import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/mediatypes`;

export const mediaTypeService = {
  getAll
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}
